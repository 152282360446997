.prosper-main {
  background-color: #5348ce;
  padding: 50px 0 20px 0;
}
.prosper-data {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 75%;
  margin: 0 auto;
}
.prosper-content1 {
  position: relative;
  width: 40%;
}
.prosper-content2 {
  width: 50%;
}
.prosper-content1 .prosper-growth-logo {
  position: absolute;
  bottom: 20px;
  right: 0;
  left: 0;
  margin: 0 auto;
}
.prosper-content1 h3 {
  font-family: "Bwbold";
  font-size: 40px;
  color: rgba(239, 239, 239, 1);
  margin: 75px 0 0 0;
}
.prosper-app-logo {
  max-width: 300px;
  margin: auto;
}
@media screen and (max-width: 1166px) {
  .prosper-data {
    width: 82%;
  }
  .prosper-content1 {
    width: 50%;
  }
}
@media screen and (max-width: 991px) {
  .prosper-data {
    width: 98%;
  }
  .prosper-content1 {
    width: 60%;
  }
  .prosper-content2 {
    width: 40%;
  }
}
@media screen and (max-width: 767px) {
  .prosper-content1,
  .prosper-content2 {
    width: 100%;
  }
  .prosper-content1 h3 {
    font-size: 30px;
    margin: 30px 0 0 0;
    text-align: center;
  }
}

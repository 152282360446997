.interestcard-sec{
    text-align: center;
}
.interestcard-sec .interestcard-img img{
    max-width: 160px;
    /* max-height: 125px; */
}
.interestcard-sec h3{
    color:#28343C;
    font-size: 24px;
    font-family: "Bwbold";
    padding: 25px 0 15px 0;
}
.interestcard-sec p{
    color:#000000;
    font-size: 16px;
    font-family: "TWKregular";
}
.interest-main{
    padding: 50px 0;
}
@media screen and (max-width:1166px){
    .interestcard-sec h3{
        font-size: 21px;
    }
    
}
@media screen and (max-width:1024px) {
    .interestcard-sec h3{
        font-size: 20px;
        padding: 25px 0 15px 0;
    }  
}
@media screen and (max-width:600px) {
    .interest-main{
        padding: 25px 0;
    } 
}
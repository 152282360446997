.bloom-contain {
  width: 96%;
  margin: 0 auto;
}
.bloomberg-title-sec {
  margin-right: 10px;
  padding: 50px 0 0 0;
  border-right: 3px solid #000;
  width: 6%;
}
.bloomberg-title {
  margin: auto 20px auto 0;
}
.bloom-loader .spinner-grow {
  background-color: #5348ce;
}
.bloomberg-title h3 {
  color: #5348ce;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 800;
  padding: 0 5px 0px 5px;
  margin: auto 0 auto 0 !important;
  text-align: center;
}
/* .bloomberg-main {
  min-height: 80px;
} */
.bloomberg-sec {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.bloom-trading-carous {
  width: 93%;
}
.bloomberg-title-sec {
  width: 6%;
}
.bloom-trading-carous .owl-carousel {
  max-height: 145px;
  /* max-height: 110px; */
}
@media screen and (max-width: 1440px) {
  .bloom-trading-carous {
    width: 90%;
  }
  .bloomberg-title-sec {
    width: 8%;
  }
}
@media screen and (max-width: 991px) {
  .bloom-trading-carous {
    width: 85%;
  }
  .bloomberg-title-sec {
    width: 12%;
  }
  .bloom-trading-carous .owl-carousel {
    max-height: unset;
  }
}
@media screen and (max-width: 767px) {
  .bloomberg-title-sec {
    padding: 20px 0 0;
  }
  .bloom-trading-carous {
    width: 75%;
  }
  .bloomberg-title-sec {
    width: 22%;
  }
}

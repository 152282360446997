.TradingCard-sec{
    padding: 20px 0;
}
.TradingCard-sec h4{
    font-size: 20px;
    font-family: "TWKbold";
    margin: 0 0 0 0 !important;
    text-align: left;
}
.TradingCard-sec p{
    font-size: 18px;
    font-family: "TWKregular";
    margin: 0 0 0 0 !important;
    text-align: left;
    padding-top: 10px;
}
.TradingCard-sec span{
    background-color: #5348CE;
    color: #fff;
    padding: 5px 15px;
    border-radius: 50px;
}
@media screen and (max-width:991px){
    .TradingCard-sec{
        padding: 10px 0;
    }
    .TradingCard-sec h4{
        font-size: 18px; 
    }
    .TradingCard-sec p{
        font-size: 16px;
    }
}
.news-title h4 {
  color: #28343c;
  font-size: 30px;
  font-family: "glancyrSemiBold";
  font-weight: 600;
  max-width: 750px;
  margin: 0 0 20px 0 !important;
}
.news-btn a {
  background-color: rgba(83, 72, 206, 0.73);
  font-size: 24px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  color: #ffffff;
  border-radius: 5px;
  padding: 8px 16px;
}
.news-sub-title h5 {
  color: #000000;
  font-size: 30px;
  font-family: "glancyrRegular";
  font-weight: 400;
  margin: 0 0 20px 0 !important;
}
.news-para p {
  color: #28343c;
  font-size: 25px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  margin: 0 0 20px 0 !important;
}
.news-logo {
  max-width: 732px;
  margin: 0 auto 25px;
}
.news-growth-logo {
  max-width: 489px;
  margin: 0 auto 40px;
}
@media screen and (max-width: 1166px) {
  .news-title h4,
  .news-sub-title h5 {
    font-size: 26px;
  }
  .news-growth-logo {
    max-width: 380px;
  }
  .news-btn a,
  .news-para p {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .news-title h4,
  .news-sub-title h5 {
    font-size: 20px;
  }
  .news-growth-logo {
    max-width: 280px;
  }
  .news-btn a,
  .news-para p {
    font-size: 16px;
  }
}

.powermain-sec{
    /* max-width: 920px; */
    /* margin:0px 0 0px auto; */
    /* padding: 30px 0; */
}
.powermain-content1{
    width: 24%;
}
@media screen and (max-width:768px){
    .powermain-content1{
        width: 45%;
        margin: 0 auto;
    }
}
@media screen and (max-width:600px){
    .powermain-content1{
        width: 90%;
        margin: 0 auto;
    }
}
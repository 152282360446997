.about2 {
  padding: 30px 0;
}
.about2-data {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.about2-data-main {
  width: 80%;
  margin: 0 auto;
}
.about2-data-content1 {
  width: 40%;
  margin: auto 0;
}
.about2-data-content1 h4 {
  color: #28343c;
  font-size: 35px;
  font-family: "glancyrRegular";
  font-weight: 500;
  margin: 0 0 30px 0;
}
.about2-data-content1 p {
  color: #28343c;
  font-size: 18px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  margin: 0 0 25px 0;
}
.about2-data-anchor .news-btn a {
  background-color: #5348ce;
}
.about2-data-content2-logo {
  max-width: 420px;
  margin: 0 auto;
}
.about2-data-content2 {
  width: 50%;
}
.about2 .expert-sec {
  background: transparent;
}
.about2 .expert-content h3 {
  max-width: 435px;
}
.about2 .expert-content p {
  padding: 64px 0 0 0;
  font-size: 16px;
}
.prosper-head {
  width: 47%;
}
.prosper-head h4 {
  font-size: 48px;
  font-family: "Bwbold";
}
.prosper-para {
  width: 53%;
}
.prosper-para p {
  font-size: 16px;
  font-family: "TWKregular";
}
.about3-main {
  margin: 100px 0 50px 0;
}
.about4-content2 p {
  color: #28343c;
  font-size: 36px;
  font-family: "TWKbold";
}
.about4 {
  padding: 40px 0 0 0;
}
.about4-content1 {
  width: 40%;
}
.about4-content2 {
  width: 53%;
}
.about2 .expertlogo,
.about2 .arrow-anchor {
  display: none;
}
.about-mar-sec {
  margin: 0 0 150px 0;
}
.Team-div,
.Team-div1 {
  width: 30%;
}
.Team-div img,
.Team-div1 img {
  max-width: 200px;
}
.Team-contain h3 {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.2;
  margin: 15px 0;
  font-family: "TWKregular";
}
.Team-contain p {
  font-size: 1.2rem;
  font-family: "TWKregular";
}
.About-main {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.About-video {
  width: 35%;
  margin: 0 auto;
}
.About-video video {
  max-width: 550px;
  max-height: 650px;
  border-radius: 50px;
}
.About-content {
  /* width: 45%;
  margin: auto 0; */
  text-align: center;
}
.About-content h4 {
  font-size: 48px;
  font-family: "BwBold";
}
.About-content p {
  font-family: "TWKregular";
  font-size: 16px;
  color: #28343c;
  padding: 0px 0 10px 0 !important;
  margin: 0 !important;
}
.about-team-data {
  /* background-color: rgba(76, 66, 183, 0.8); */
  padding: 30px 0;
  margin: 30px 0 50px;
}
.about-team-data h4 {
  color: #fff;
  font-size: 48px;
  font-family: "BwBold";
  text-align: center;
}
.about-team-data h6 {
  color: rgba(40, 52, 60, 1);
  font-size: 25px;
  font-family: "TWKregular";
  text-align: center;
  margin: 0 0 0 0 !important;
}
.about-team-data p {
  color: #fff;
  font-size: 30px;
  font-family: "BwBold";
  text-align: center;
}
.about-documentary {
  display: flex;
  justify-content: center;
}
.about-documentary .about-doc-logo {
  margin: 0 20px 0 0;
}
.about-documentary .about-doc-logo img {
  max-width: 250px;
}
.about-documentary p {
  width: 80%;
}
.about-ceo-content {
  padding: 35px 0 0 0 !important;
}
.about-documentary a {
  color: rgba(83, 72, 206, 1);
  font-size: 20px;
  font-family: "TWKregular";
  text-decoration: underline;
}
/* .about-team-container {
  max-width: 1440px;
  margin: 0 auto;
} */
@media screen and (max-width: 1440px) {
  .about2-data-main {
    width: 90%;
  }
  .about2-data-content1 {
    width: 45%;
  }
}
@media screen and (max-width: 1166px) {
  /* .about-team-container {
    width: 95%;
  } */
  .About-content h4 {
    font-size: 40px;
  }
  .about-documentary a {
    font-size: 16px;
  }
  .about2-data-content1 h4 {
    font-size: 26px;
  }
  .about2-data-content1 p {
    font-size: 16px;
  }
  .about2-data-content2-logo {
    max-width: 350px;
  }
}
@media screen and (max-width: 1024px) {
  .about2 .expert-content p {
    padding: 35px 0 0 0;
  }
  .prosper-head h4 {
    font-size: 36px;
  }
  .about4-content2 p {
    font-size: 30px;
  }
}
@media screen and (max-width: 991px) {
  .about2-data-content1,
  .about2-data-content2 {
    width: 90%;
    margin: 0 auto 30px;
  }
  .about2 .expert-content p {
    padding: 0px 0 0 0;
  }
  .Team-div {
    width: 40%;
  }
  .prosper-head h4 {
    font-size: 30px;
  }
  .about4-content1 {
    width: 42%;
  }
  .about4-content2 p {
    font-size: 24px;
  }
  .about-mar-sec {
    margin: 0 0 70px 0;
  }
  .About-video {
    width: 55%;
    margin: 0 auto;
  }
  .About-content h4 {
    font-size: 32px;
  }
  .about-team-data h4 {
    font-size: 30px;
  }
  .about-team-data h6 {
    font-size: 18px;
  }
  .about-team-data p {
    font-size: 24px;
  }
}
@media screen and (max-width: 767px) {
  .about2-data-content1,
  .about2-data-content2 {
    width: 100%;
  }
  .about5 {
    margin: 0 0 50px 0;
  }
  .Team-div,
  .Team-div1 {
    width: 100%;
  }
  .prosper-head,
  .prosper-para,
  .about4-content1,
  .about4-content2 {
    width: 97%;
    margin: 0 auto;
  }
  .prosper-head h4 {
    font-size: 28px;
  }
  .About-video {
    width: 95%;
    margin: 0 auto;
  }
  .About-content {
    width: 98%;
    margin: auto 0;
  }
  .About-content h4,
  .About-content p {
    text-align: center;
  }
  .about-team-data h4 {
    font-size: 20px;
  }
}

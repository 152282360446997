.Btn-main{
    margin: 25px 0 35px 0;
    width: 100%;
}
.Btn-main a{
    background-color: #5348CE;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    text-align: center;
    padding: 18px 150px;
}
.Btn-main a:hover{
    color: #fff;
}
@media screen and (max-width:1024px){
    .Btn-main a{
        padding: 15px 120px;
    }
}
@media screen and (max-width:768px){
    .Btn-main a{
        padding: 15px 100px;
    }
}
@media screen and (max-width:320px){
    .Btn-main a{
        padding: 15px 90px;
    }
}
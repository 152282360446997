.proposition-main {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 80%;
  margin: 70px auto;
}
.proposition-content1,
.proposition-content2 {
  width: 48%;
}
.proposition-content1 h4 {
  font-size: 35px;
  font-family: "Bwbold";
  color: #28343c;
  margin: 0 0 25px 0;
}
.proposition-content1 p {
  font-size: 18px;
  font-family: "TWKregular";
  color: #252626;
  margin: 0 0 15px 0;
  max-width: 530px;
}
.proposition-content1 h4,
.proposition-content2 h5,
.proposition-content1 h5 {
  font-size: 25px;
  font-family: "Bwbold";
  color: #28343c;
  margin: 0 0 15px 0;
}
.proposition-content1 h5 {
  margin: 20px 0 15px 0 !important;
}
.proposition-content2 h4 {
  font-size: 28px;
  font-family: "Bwbold";
  color: #28343c;
}
.proposition-detail p {
  margin: 0 0 0 0 !important;
}
.proposition-detail p span {
  font-size: 18px;
  font-family: "TWKregular";
  color: #252626;
  margin-right: 5px;
}
.propositionEstimate-logo img {
  max-width: 550px;
}
.proposition-profile-logoNumber {
  margin: 0 0 30px 0;
}
.proposition-profile-logo {
  display: flex;
  justify-content: space-between;
  background-color: #000000;
  padding: 8px 12px;
  margin: 0 0 25px 0;
}
.proposition-profile-logo h6 {
  font-size: 16px;
  color: #fff;
  font-family: "Bwbold";
}
.proposition-profile-logo p {
  font-size: 16px;
  color: #fff;
  font-family: "Bwregular";
  margin: 0 0 0 0 !important;
}
@media screen and (max-width: 1440px) {
  .proposition-main {
    width: 90%;
  }
}
@media screen and (max-width: 991px) {
  .proposition-content1,
  .proposition-content2 {
    width: 100%;
    margin: 0 0 25px 0;
  }
  .proposition-content1 p {
    max-width: unset;
  }
}
@media screen and (max-width: 767px) {
  .proposition-main {
    flex-direction: column-reverse;
    margin: 40px auto;
  }
  .proposition-profile-logo {
    display: flex;
    flex-direction: column;
  }
  .proposition-profile1 {
    margin: 0 0 15px 0;
  }
  .proposition-content1 h4,
  .proposition-content2 h5,
  .proposition-content1 h5,
  .proposition-content2 h4 {
    font-size: 20px;
  }
}

.trading-graph-main {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 60%;
}
.invester-section-main {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.investerSlider-sec-main {
  width: 40%;
}
.riskValue-section {
  width: 55%;
  margin: 0 0 30px 0;
}
.riskValue-change {
  display: flex;
  justify-content: space-around;
}
.highlight {
  background-color: #5348ce;
}
.unHighlight {
  background-color: #000000;
}
.riskValue-change-data span {
  padding: 10px 33px;
  margin: 5px;
  border-radius: 5px;
  font-family: "Bwbold";
  color: #fff;
}
.riskValue-data {
  display: flex;
  justify-content: space-between;
  background-color: #000000;
  padding: 8px 10px;
  margin: 0 0 15px 0;
}
.riskValue-data p {
  font-size: 16px;
  color: #fff;
  font-family: "Bwregular";
  margin: 0 0 0 0 !important;
  text-align: center;
}
.riskValue-data h6 {
  font-size: 16px;
  color: #fff;
  font-family: "Bwbold";
  text-align: center;
}
.riskValue-content h4 {
  font-size: 28px;
  font-family: "Bwbold";
  color: #28343c;
}
.investerSlider-sec-main {
  margin: auto 0;
}
.trading-initail,
.trading-asset-backed {
  width: 20%;
  margin-bottom: 120px;
}
.Graph-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 50px;
}
.trading-plot1 canvas {
  width: 70% !important;
  height: auto !important;
  margin: 0 0 0 0;
}
.trading-plot2 canvas {
  width: 100% !important;
  height: auto !important;
}
.trading-plot1 {
  width: 40%;
  max-height: 350px;
}
.trading-plot2 {
  width: 55%;
  max-height: 350px;
}
.trading-initial label,
.trading-asset-backed label {
  font-size: 1.3rem;
  color: #5348ce;
}
@media screen and (max-width: 1166px) {
  .riskValue-change-data span {
    padding: 10px 27px;
    margin: 4px;
  }
}
@media screen and (max-width: 991px) {
  .trading-initial,
  .trading-asset-backed {
    margin-bottom: 80px;
  }
  .trading-graph-main {
    width: 80%;
  }
  .trading-plot1 {
    width: 90%;
    margin: 0 auto 70px auto;
  }
  .trading-plot1 canvas {
    width: 60% !important;
    margin: 0 auto;
  }
  .trading-plot2 {
    width: 90%;
    margin: 0 auto;
  }
  .investerSlider-sec-main {
    width: 100%;
  }
  .riskValue-section {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .trading-graph-main {
    width: 100%;
  }
  .trading-initial,
  .trading-asset-backed {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .trading-plot1 canvas {
    width: 100% !important;
    margin: 0 auto;
  }
  .trading-plot1 {
    width: 100%;
    margin: 0 auto 40px auto;
  }
  .trading-plot2 {
    width: 100%;
    margin: 0 auto;
    max-height: unset;
  }
  .trading-plot2 canvas {
    height: 300px !important;
  }
  .riskValue-change-data span {
    padding: 10px 16px;
    margin: 4px;
  }
  .riskValue-data p {
    font-size: 14px;
  }
  .riskValue-content h4 {
    font-size: 24px;
  }
}

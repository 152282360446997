.mobile-main {
  padding: 50px 15px;
}
.mobile-head-content h3 {
  font-size: 3.5rem;
  font-weight: 600;
  line-height: 1.2;
}
.mobile-head-content p {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.2;
}
.mobile-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobile-tabs a {
  min-width: 160px;
  text-align: center;
  text-transform: capitalize;
  border: 1px #000;
  border-radius: 12px;
  padding: 0.75rem 1.5rem;
  font-weight: 500;
  line-height: 1.16;
  font-family: "TWKregular";
}
.mobile-tabs .mobile-anc1 a {
  background-color: #f8d234;
  color: #303030;
  margin: 0 15px 0 0px;
}
.mobile-anc1 .letcall-main .letcall-btn button {
  background-color: #f8d234;
  color: #303030;
  margin: 0 15px 0 0px;
  outline: none;
  border: none;
  padding: 10px 20px 10px 20px;
  border-radius: 10px;
  font-family: "TWKregular";
}
.mobile-anc1 .letcall-main .letcall-btn button:focus {
  outline: none !important;
}
.mobile-tabs .mobile-anc2 a {
  background-color: #5348ce;
  color: #fff;
}
.mobile-tab-image {
  margin: 20px 0;
}
.mobile-tab-image img {
  max-width: 300px;
}
.mobile-content-data {
  width: 52%;
}
.mobile-content2 {
  display: flex;
  justify-content: space-around;
}
.mobile-content2 h3 {
  text-align: left;
}
.trade-sec .TradingCard-sec p span {
  background-color: #378a3e;
}
.mobile-content-data1 {
  width: 28%;
  margin: auto 0;
}
.mobile-content-data2 {
  width: 28%;
  margin: auto 0;
}
.mobile-content-data2 a,
.trade-sec a {
  color: #000;
}
.mobile-content-data2 a:hover,
.trade-sec a:hover {
  color: #5348ce;
  cursor: pointer;
}
.mobile-tab-image p {
  font-size: 18px;
  font-family: "Bwregular";
}
@media screen and (max-width: 991px) {
  .mobile-head-content h3 {
    font-size: 2.5rem;
  }
  .mobile-head-content p {
    font-size: 1.2rem;
  }
  /* .mobile-tab-image img {
    max-width: 300px;
  } */
  .mobile-content-data1 {
    width: 45%;
  }
  .mobile-content-data2 {
    width: 90%;
  }
}
@media screen and (max-width: 767px) {
  .mobile-tab-image {
    margin: 0 auto;
  }
  .mobile-tab-image img {
    /* max-width: 300px; */
    margin: 0 auto;
  }
  .mobile-content-data,
  .mobile-content-data2,
  .mobile-content-data1 {
    width: 90%;
    margin: 25px auto;
  }
  .mobile-anc1 .letcall-main .letcall-btn button {
    margin: 0 0px 0 0px;
  }
}
@media screen and (max-width: 600px) {
  .mobile-main {
    padding: 30px 0;
  }
}
@media screen and (max-width: 474px) {
  .mobile-tabs {
    flex-direction: column;
  }
  .mobile-anc1 {
    margin-bottom: 30px;
  }
}

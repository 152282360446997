.image-item img {
  max-width: 180px;
  margin: 0 auto;
}
.item-content h3 {
  font-size: 28px;
  font-family: "Bwbold";
}
.item-content p {
  font-size: 18px;
  font-family: "Bwregular";
  max-width: 360px;
  margin: 0 auto;
}
@media screen and (max-width: 1024) {
  .item-content p {
    max-width: 280px;
  }
}
@media screen and (max-width: 767px) {
  .image-item img {
    max-width: 150px;
  }
}

.arrow-btn-main span {
  margin: -4px 0 0 8px;
}
.arrow-btn-main span img {
  width: 18px;
  height: 18px;
}
.arrow-btn-main a {
  font-size: 30px;
  font-family: "TWKregular";
  color: #5348ce;
}
@media screen and (max-width: 991px) {
  .arrow-btn-main a {
    font-size: 22px;
  }
}

.launch-card-main {
  text-align: center;
}
.launch-main-jet img {
  max-width: 140px;
}
.launch-date {
  display: flex;
  margin: 15px 0;
}
.launch-date h6 {
  color: #5348ce;
  font-size: 18px;
  font-family: "Bwbold";
  padding: 0px 0 0 0;
}
.launch-date span {
  margin: -2px 5px 0 0;
  background-color: #5348ce;
  padding: 3px 12px;
  border-radius: 50px;
  box-shadow: 0px 0px 2px 0px #000000;
}
.launch-date span img {
  width: 24px;
  height: 24px;
}
.launch-detail p {
  color: #fff;
  font-size: 22px;
  background-color: #000000;
  font-family: "Bwregular";
  padding: 5px 8px !important;
  text-align: center;
  border-radius: 5px;
}
@media screen and (max-width: 1366px) {
  .launch-detail p {
    font-size: 20px;
  }
}
@media screen and (max-width: 1166px) {
  .launch-detail p {
    font-size: 18px;
  }
  .launch-date h6 {
    font-size: 16px;
  }
}
@media screen and (max-width: 991px) {
   .launch-detail p {
    font-size: 18px;
  }
}

.privacy-content{
    padding: 30px 0;
}
.privacy-content h3{
    text-align: center;
    color: #5348CE;
    font-size: 40px;
    font-family: "TWKbold";
    text-align: center;
}
.privacy-content ul{ 
    margin-left: 20px;  
}
.privacy-content ul li{
    list-style-type: square;
    font-size: 18px;
    color: #28343C;
}
.privacy-content ol li{
    list-style-type: decimal;
    font-size: 18px;
    color:#5348CE;
    font-weight: bold;
    margin-left: 20px;
}
.privacy-content h4{
    font-size: 26px;
    font-family: "TWKbold";
    color: #5348CE;
}
.privacy-content p{
    font-size: 18px;
    font-family: "TWKregular";
    color: #28343C;;
}
.privacy-content span ,a{
    text-decoration: underline;
    color: #5348CE;
    font-weight: bold;
}
.privacy-content a:hover{
    text-decoration: underline;
    color: #5348CE;
}
@media screen and (max-width:991px){
    .privacy-content ol li{
        font-size: 16px;
    }
    .privacy-content h4{
        font-size: 22px;
    }
}
@media screen and (max-width:767px){
    .privacy-content h3{
        font-size: 30px;
    }
    .privacy-content h4{
        font-size: 22px;
    }
}
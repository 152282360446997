.discover-main {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.discover-content1 {
  width: 40%;
}
.discover-content2 {
  width: 60%;
}
.discover-content-inner {
  width: 65%;
  margin: auto;
}
.discover-content1 h4 {
  max-width: 350px;
  font-family: "Bwbold";
  font-size: 49px;
  color: #28343c;
}
.discover-app-anchors span {
  margin: 0 15px 0 0;
}
.discover-app-anchors span a img {
  max-width: 150px;
}
@media screen and (max-width: 1166px) {
  .discover-content-inner {
    width: 85%;
  }
}
@media screen and (max-width: 991px) {
  .discover-content-inner {
    width: 95%;
  }
  .discover-content1 h4 {
    max-width: 300px;
    font-size: 36px;
  }
  .discover-app-anchors span a img {
    max-width: 120px;
  }
}
@media screen and (max-width: 767px) {
  .discover-content1 {
    width: 95%;
    margin: 0 auto;
  }
  .discover-content2 {
    width: 100%;
  }
  .discover-content-inner {
    width: 95%;
  }
}

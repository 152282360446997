.Phone-main{
    background-color: #5348CE;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 999;
}
.Phone-icon{
    color: #fff;
    font-size: 24px;
}
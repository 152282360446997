body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "TWKregular";
  src: local("TWKregular"), url("./assets/fonts/TWKLausanne-300.ttf");
}
@font-face {
  font-family: "TWKbold";
  src: local("TWKbold"), url("./assets/fonts/TWKLausanne-600.ttf");
}
@font-face {
  font-family: "Bwbold";
  src: local("Bwbold"), url("./assets/fonts/BwGradualDEMO-Bold.otf");
}
@font-face {
  font-family: "Bwextrabold";
  src: local("Bwextrabold"), url("./assets/fonts/BwGradualDEMO-ExtraBold.otf");
}
@font-face {
  font-family: "Bwlight";
  src: local("Bwlight"), url("./assets/fonts/BwGradualDEMO-Light.otf");
}
@font-face {
  font-family: "Bwmedium";
  src: local("Bwmedium"), url("./assets/fonts/BwGradualDEMO-Medium.otf");
}
@font-face {
  font-family: "Bwregular";
  src: local("Bwregular"), url("./assets/fonts/BwGradualDEMO-Regular.otf");
}
@font-face {
  font-family: "Bwthin";
  src: local("Bwthin"), url("./assets/fonts/BwGradualDEMO-Thin.otf");
}
@font-face {
  font-family: "NimbusBold";
  src: local("NimbusBold"), url("./assets/fonts/NimbusMono-Bold.otf");
}

@font-face {
  font-family: "glancyrRegular";
  src: local("glancyrRegular"), url("./assets/fonts/Glancyr-Regular.otf");
}
@font-face {
  font-family: "glancyrBold";
  src: local("glancyrBold"), url("./assets/fonts/Glancyr-Bold.otf");
}
@font-face {
  font-family: "glancyrSemiBold";
  src: local("glancyrSemiBold"), url("./assets/fonts/Glancyr-SemiBold.otf");
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/*universal style*/
* {
  box-sizing: border-box;
}
img {
  width: 100%;
  height: auto;
}
a {
  text-decoration: none !important;
}
a:hover {
  text-decoration: none !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
ul,
ol,
li {
  padding: 0;
  margin: 0;
}
ol,
ul {
  list-style-type: none;
  padding-left: 0 !important;
}
/* .translated-ltr {
  margin-top: -40px;
}
.goog-te-banner-frame {
  display: none;
  margin-top: -20px;
}
.goog-logo-link {
  display: none !important;
}
.goog-te-gadget {
  color: transparent !important;
  display: none !important; 
}
.goog-te-gadget a {
  display: none;
} */
.VIpgJd-ZVi9od-ORHb {
  margin-top: -40px !important;
  display: none !important;
}
.react-pdf__Document .react-pdf__message.react-pdf__message--error{
  display: none;
}
/* .skiptranslate{
  margin-top: -30px;
}*/

/* .goog-te-gadget select {
  position: sticky; 
  position: absolute;
  right: 310px;
  top: 100px;
  z-index: 1050;
  border: 2px solid #5348ce;
  border-radius: 25px;
  width: 150px;
  padding: 8px 0;
} */
.skiptranslate iframe {
  /* margin-top: -40px !important; */
  display: none !important;
  /* opacity: 0; */
}
/* .goog-te-gadget{
  position: sticky;
  top: 120px;
} */
/* .goog-te-gadget select option {
  color: #5348ce;
  font-family: "Bwbold";
} */
.VIpgJd-ZVi9od-aZ2wEe,
.VIpgJd-ZVi9od-aZ2wEe-OiiCO {
  display: none !important;
}
/* @media screen and (max-width: 1440px) {
  .goog-te-gadget select {
    right: 230px;
  }
} */
/* @media screen and (max-width: 1024px) {
  .goog-te-gadget select {
    right: 150px;
  }
} */
/* @media screen and (max-width: 767px) {
  .goog-te-gadget select {
    top: 83px;
    width: 100px;
    right: 75px;
  }
} */
/* @media screen and (max-width: 474px) {
  .goog-te-gadget select {
    bottom: 230px;
    top: unset;
    width: 170px;
    margin: 0 auto;
    right: 0;
    left: 10px;
  }
} */

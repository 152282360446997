.frame3-arrow {
  padding: 30px 0 0 0;
}
.frame3-about-data,
.frame3-arrow {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.frame3-about-data {
  margin: 30px 0;
}
.frame3-about .heading-main h3 {
  font-size: 35px;
  font-family: "Bwbold";
  margin: 30px 0;
}
.frame3-about,
.frame3-arrow {
  width: 80%;
  margin: 0px auto 0;
}
.frame3-arrow-content1,
.frame3-arrow-content2 {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.frame3-about-content1,
.frame3-about-content2,
.frame3-about-content3,
.frame3-about-content4 {
  width: 18%;
}
.main-header-abs3 {
  position: absolute;
  top: 0;
  left: 30px;
  bottom: 0;
  height: fit-content;
  width: fit-content;
  margin: auto;
}
.main-header-abs3 p {
  padding: 8px 15px;
  color: #000;
  font-size: 26px;
  font-family: "TWKbold";
  border-radius: 8px;
}
.main-header-abs3 img {
  width: 550px;
}
@media screen and (max-width: 1440px) {
  .frame3-about,
  .frame3-arrow {
    width: 90%;
  }
  .frame3-about-content1,
  .frame3-about-content2,
  .frame3-about-content3,
  .frame3-about-content4 {
    width: 21%;
  }
}
@media screen and (max-width: 1166px) {
  .frame3-about-content1,
  .frame3-about-content2,
  .frame3-about-content3,
  .frame3-about-content4 {
    width: 24%;
  }
}
@media screen and (max-width: 991px) {
  .frame3-about-content1,
  .frame3-about-content2,
  .frame3-about-content3,
  .frame3-about-content4 {
    width: 48%;
  }
  .main-header-abs3 img {
    width: 400px;
  }
  .main-header-abs3 p {
    padding: 8px 8px;
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .frame3-arrow-content1,
  .frame3-arrow-content2 {
    width: 100%;
    margin: 0 0 15px 0;
  }
  .frame3-about .heading-main h3 {
    font-size: 26px;
  }
  .frame3-about-content1,
  .frame3-about-content2,
  .frame3-about-content3,
  .frame3-about-content4 {
    width: 100%;
  }
  .main-header-abs3 img {
    width: 250px;
    height: 65px;
  }
  .main-header-abs3 p {
    font-size: 16px;
  }
}

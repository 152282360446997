.investSlider p,.backedSlider p{
    font-size: 22px;
    color: #5348CE ;
    font-family: "TWKbold";
    padding: 0 0 0 0 !important;
    margin: 0 0 0 0 !important;
}
.investSlider .css-187mznn-MuiSlider-root,.backedSlider .css-187mznn-MuiSlider-root,.investSlider .css-exkjcx{
    color: #5348CE !important;
}
.graph-data-sets{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.sliderdataPie{
    width: 45%;
    max-height: 400px;
}
.sliderdatasine{
    width: 53%;
}
.sliderdataPie canvas,.sliderdatasine canvas{
    width: unset !important;
    height: unset !important;
}
@media screen and(max-width:991px){
    .graph-data-sets{
        flex-direction: column;
    }
    .sliderdataPie canvas{
        width: 95% !important;
        margin: 0 auto;
    }
    .sliderdatasine{
        width: 95%;
        margin: 0 auto; 
    }
}
.track-content h3 ,.track-content p, .track-data h5{
    color:#000;
    max-width: 730px;
    margin: 0 auto;
}
.track-content{
    padding: 40px 0;
}
.track-power-bg{
    background-color: #C3BEFE;
    padding: 15px 0;
}
.track-data span{
    background-color: #5348CE;
    color: #fff;
    padding: 1px 2px;
    border-radius: 5px;
}
.track-data h4{
    color: #000;
    font-size: 36px;
    font-family: "Bwbold";
    padding: 10px 0 0px 0;
}
.track-data h5,.track-data p{
    color: #28343c;
    font-family: "TWKregular";
    font-size: 20px;
}
.track-data p{
    padding: 20px 0;
}
@media screen and (max-width:991px){
    .track-data p{
        padding: 10px 0;
    }
}
@media screen and (max-width:767px){
    .track-data h4{
        font-size: 30px;
    }
    .track-data h5,.track-data p{
        font-size: 18px;
    }
}
.afm-reg{
    padding: 70px 0;
}
.afm-reg-head{
    width: 40%;
}
.afm-reg-para{
    width: 44%;
}
.afm-reg-head h3{
    font-family: 'Bwbold';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 49px;
    color: #000000;
}
.afm-content-main{
    margin: 50px 0 130px 0;
    border-top: 1px solid #0F1B23;
    padding: 120px 0 0 0;
}
.afm-content-main .afm-content-image{
    max-width: 470px;
    margin: auto 0;
}
.afm-content-sec{
    width: 50%;
}
.afm-content-sec .afm-content-logo img{
    max-width: 142px;
}
.afm-content-sec .heading-main h3{
    margin: 0 !important;
    font-size: 21px;
}
.afm-content-sec .para-content p{
    max-width: 585px;
    margin: 0 0 10px 0 !important;
}
.registeration-sec{
    padding: 90px 0 35px 0;
}
.afm-registeration{
    margin: 100px 0 130px 0;
}
.afm-registeration-head h3{
    max-width: 224px;
    margin: 0 auto;
    text-align: center;
}
.registeration-card{
    width: 23%;
}
@media screen and (max-width:1166px) {
    .afm-reg-head h3{
        font-size: 40px;
    }
    .afm-content-main .afm-content-image{
        max-width: 445px;
    }
    .afm-registeration{
        margin: 0px 0 100px 0;
    }
    .registeration-card{
        width: 28%;
    }
}
@media screen and (max-width:991px) {
    .afm-reg-head{
        width: 50%;
    }
    .afm-reg-head h3{
        font-size: 36px;
    }
    .afm-content-main .afm-content-image{
        max-width: unset;
        margin: 0 auto;
    }
    .afm-content-sec .para-content p{
        max-width: unset;
    }
    .afm-content-main{
        margin: 80px 0 80px 0;
        padding: 80px 0 0 0;
    }
    .afm-content-sec{
        width: 100%;
    }
    .registeration-card{
        width: 44%;
        margin: 0 0 25px 0;
    }
}
@media screen and (max-width:767px) {
    .afm-reg-head,.afm-reg-para{
        width: 100%;
    }
    .afm-content-main{
        margin: 40px 0 40px 0;
        padding: 40px 0 0 0;
    }
    .afm-reg-head h3 {
        font-size: 32px;
    }
    .afm-reg{
        padding: 40px 0;
    }
    .registeration-card{
        width: 87%;
        margin: 0 auto 25px auto;
    }
    .afm-registeration{
        margin: 60px 0 40px 0;
    }
    
}

.arrow-anchor{
    max-width: 270px;
}
.arrow-anchor a{
    color: #5348CE;
    font-family: "TWKregular";
    font-size: 18px;
}
.arrow-anchor a:hover{
    color: #5348CE;
} 
.arrow-img{
    background-color: #5348CE;
    border-radius: 50%;
    padding: 1.4px 7px;
    margin-left: 10px;
}
.arrow-img span{
    font-size: 18px;
    color: #fff;
}
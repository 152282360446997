.expert-sec {
  background: rgba(136, 125, 253, 0.5);
  padding: 60px 0;
}
.expert-content h3 {
  font-size: 40px;
  font-family: "Bwbold";
  color: #28343c;
}
.expert-content p {
  font-size: 24px;
  font-family: "TWKregular";
  color: #28343c;
  max-width: 455px;
}
.expert-para-logo {
  position: relative;
}
.expert1 {
  width: 42%;
}
.expert2 {
  width: 42%;
}
.expertlogo img {
  position: absolute;
  margin: -7px 70px 0 0px;
  right: 0;
}
.expertlogo img {
  max-width: 50px;
}
@media screen and (max-width: 1366px) {
  .expertlogo img {
    margin: -7px -8px 0 0px;
  }
}
@media screen and (max-width: 1166px) {
  .expert-content h3 {
    font-size: 30px;
  }
  .expertlogo img {
    margin: -7px 20px 0 0px;
  }
}
@media screen and (max-width: 768px) {
  .expert1 {
    width: 45%;
  }
  .expert2 {
    width: 50%;
  }
  .expertlogo img {
    margin: -7px -20px 0 0px;
  }
  .expert-sec {
    padding: 30px 0;
  }
}
@media screen and (max-width: 600px) {
  .expert1,
  .expert2 {
    width: 90%;
    margin: 0 auto;
  }
  .expert-content h3 {
    font-size: 24px;
  }
  .expert-content p {
    font-size: 22px;
    max-width: 350px;
  }
  .expertlogo img {
    margin: -7px -28px 0 0px;
  }
}

.contactcard-main .contactcard-image img{
    width: 22px;
    height: 22px;
}
.contactcard-main h4{
    font-size: 20px;
    color: #101828;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    line-height: 30px;
    /* margin: 0 !important; */
}
.contactcard-main p{
    font-size: 16px;
    color: #101828;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    line-height: 24px;
    margin: 0 !important;
    padding: 6px 0;

}
.contactcard-main a{
    font-size: 16px;
    color: #5348CE;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    line-height: 24px;
}
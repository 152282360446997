.what-data {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.what-we-do {
  width: 80%;
  margin: 50px auto 0px;
}
.what-we-do .heading-main h3 {
  font-size: 35px;
  font-family: "Bwbold";
  margin: 30px 0;
}
.what-content1,
.what-content2,
.what-content3 {
  width: 27%;
}
.what-content1 .interestcard-sec .interestcard-img img,
.what-content2 .interestcard-sec .interestcard-img img,
.what-content3 .interestcard-sec .interestcard-img img {
  width: 115px;
}
.what-content1 .interestcard-sec .interestcard-img,
.what-content2 .interestcard-sec .interestcard-img,
.what-content3 .interestcard-sec .interestcard-img {
  margin: 0 0 50px 0;
}
.what-content1 .interestcard-sec p,
.what-content2 .interestcard-sec p,
.what-content3.interestcard-sec p {
  color: #252626;
}
@media screen and (max-width: 1440px) {
  .what-we-do {
    width: 90%;
  }
  .what-content1,
  .what-content2,
  .what-content3 {
    width: 31%;
  }
  .what-content1 .interestcard-sec .interestcard-img,
  .what-content2 .interestcard-sec .interestcard-img,
  .what-content3 .interestcard-sec .interestcard-img {
    margin: 0 0 30px 0;
  }
}
@media screen and (max-width: 991px) {
  .what-content1,
  .what-content2,
  .what-content3 {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .what-we-do .heading-main h3 {
    font-size: 26px;
  }
}

.latest-news-main{
  margin: 50px 0 0 0;
}
.latest-news-card {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; 
}
.latest-news-title h4 {
  text-align: center;
  font-size: 50px;
  font-family: "TWKbold";
  color: #28343c;
  margin: 15px 0 30px 0;
}
.latest-news-card-content {
  width: 32%;
  margin: 0 0 25px 0;
}
@media screen and (max-width: 991px) {
  .latest-news-card-content {
    width: 48%;
  }
}
@media screen and (max-width: 767px) {
  .latest-news-title h4 {
    font-size: 30px;
  }
  .latest-news-card-content {
    width: 98%;
    margin: 0 auto 25px;
  }
}

.main-header-abs2 {
  position: absolute;
  top: 0;
  right: 30px;
  bottom: 0;
  height: fit-content;
  width: fit-content;
  margin: auto;
}
.main-header-abs2 p {
  padding: 8px 15px;
  color: #000;
  font-size: 26px;
  font-family: "TWKbold";
  border-radius: 8px;
}
.main-header-abs2 img {
  width: 550px;
}
@media screen and (max-width: 991px) {
  .main-header-abs2 img {
    width: 400px;
  }
  .main-header-abs2 p {
    padding: 8px 8px;
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .main-header-abs2 img {
    width: 250px;
    height: 65px;
  }
  
  .main-header-abs2 p {
    font-size: 16px;
  }
}

.numbermain {
  border-bottom: 1px solid #000;
  padding: 30px 0;
  margin: 0 0 50px 0;
}
.numbercontent {
  width: 28%;
}
@media screen and (max-width: 991px) {
  .numbercontent {
    width: 31%;
  }
}
@media screen and (max-width: 767px) {
  .numbercontent {
    width: 80%;
    margin: 0 auto;
  }
}

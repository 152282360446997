.fund-card-text {
  margin: 15px 0 0 0;
}
.fund-card-text h4 {
  color: #fff;
  font-size: 17px;
  background-color: #000000;
  font-family: "Bwregular";
  padding: 5px 8px !important;
  text-align: center;
}
@media screen and (max-width: 1166px) {
  .fund-card-text h4 {
    font-size: 14px;
    padding: 5px 0px;
  }
}

.dropmenu-sec .dropmenu-pic img{
    width: 22px;
    height: 20px;
    margin-right: 15px;
}
.dropmenu-sec .dropmenu-content h3{
    color: #101828;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    padding: 3px 0;
    font-weight: 600;
}
.dropmenu-sec .dropmenu-content h3 span{
    color: #027A48;
    font-size: 10px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    background: #ECFDF3;
    padding: 2px 10px;
    margin: 0 !important;
}
.dropmenu-sec .dropmenu-content p{
    color: #667085;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    margin: 0 !important;
}
@media screen and (max-width:768px) {
    .dropmenu-sec .dropmenu-content h3 {
    /* padding: 3px 0; */
    padding: 0 !important;
    margin: 0 !important;
    }
}

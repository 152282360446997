.contact-content{
    width: 40%;
    margin:0 0 30px  0;
}
.contact-main{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
@media screen and (max-width:767px){
    
    .contact-main{
       flex-direction: column;
    }
    .contact-content{
        width: 60%;
        text-align: center;
        margin:0 auto 30px auto;
    }
}
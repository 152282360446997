.Have-main-bg {
  background-color: #5348ce;
  border-radius: 25px;
  margin: 0 0 100px 0;
}
.Have-content1 {
  width: 48%;
}
.Have-content2 {
  width: 40%;
}
.Have-main {
  width: 90%;
  padding: 30px 0;
  margin: 0px auto;
}
@media screen and (max-width: 768px) {
  .Have-content1{
    width: 100%;
    margin-bottom: 20px;
  }
  .Have-content2 {
    width: 50%;
    margin: 0 auto 20px;
  }
}

.number-text-main {
  text-align: center;
}
.number-text-main h4 {
  color: #000;
  font-size: 50px;
  font-family: "Caladea", serif;
  font-weight: 400;
  margin: 0 0 20px 0 !important;
}
.number-text-main p {
  color: #252626;
  font-size: 21px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
}
@media screen and (max-width: 1166px) {
  .number-text-main p {
    font-size: 18px;
  }
}
@media screen and (max-width: 991px) {
  .number-text-main h4 {
    font-size: 36px;
  }
}

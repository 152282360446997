.frame4-main {
  width: 80%;
  margin: 0 auto;
}
.frame4-about-data {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.frame4-about {
  margin: 100px 0;
}
.frame4-about-content1,
.frame4-about-content2 {
  width: 50%;
}
.frame4-what-we-do .heading-main h3,
.frame4-about .heading-main h3 {
  font-size: 35px;
  font-family: "Bwbold";
  margin: 30px 0;
}
.frame4-what-we-do
  .what-data
  .what-content1
  .interestcard-sec
  .interestcard-img
  img,
.frame4-what-we-do
  .what-data
  .what-content2
  .interestcard-sec
  .interestcard-img
  img,
.frame4-what-we-do
  .what-data
  .what-content3
  .interestcard-sec
  .interestcard-img
  img {
  width: 75px;
  height: 70px;
}
.frame4-what-we-do
  .what-data
  .what-content1
  .interestcard-sec
  .interestcard-img,
.frame4-what-we-do
  .what-data
  .what-content2
  .interestcard-sec
  .interestcard-img,
.frame4-what-we-do
  .what-data
  .what-content3
  .interestcard-sec
  .interestcard-img {
  margin: 0 0 30px 0;
}
.about-content2-inner1,
.about-content2-inner1,
.about-content2-inner1 {
  margin: 0 0 30px 0;
}
.frame4-about-content2 .about-content2-inner1 .number-text-main h4,
.frame4-about-content2 .about-content2-inner2 .number-text-main h4,
.frame4-about-content2 .about-content2-inner3 .number-text-main h4 {
  font-family: "NimbusBold";
  font-size: 50px;
  color: #000;
}
.frame4-about-content2 .about-content2-inner1 .number-text-main p,
.frame4-about-content2 .about-content2-inner2 .number-text-main p,
.frame4-about-content2 .about-content2-inner3 .number-text-main p {
  font-family: "NimbusBold";
  font-size: 20px;
  color: #000;
}
.main-header-abs4 {
  position: absolute;
  top: 0;
  left: 30px;
  bottom: 0;
  height: fit-content;
  width: fit-content;
  margin: auto;
}
.main-header-abs4 p {
  margin: 10px 0 0 0;
}
.main-header-abs4 span {
  padding: 8px 15px;
  margin: 10px 0 0 0;
  color: #fff;
  font-size: 26px;
  font-family: "TWKbold";
  background-color: #000;
}
.main-header-abs4 img {
  width: 550px;
  background-color: #000;
}
@media screen and (max-width: 1440px) {
  .frame4-main {
    width: 90%;
  }
}
@media screen and (max-width: 1166px) {
  .frame4-about-content2 .about-content2-inner1 .number-text-main h4,
  .frame4-about-content2 .about-content2-inner2 .number-text-main h4,
  .frame4-about-content2 .about-content2-inner3 .number-text-main h4 {
    font-family: "NimbusBold";
    font-size: 40px;
    color: #000;
  }
  .frame4-about-content2 .about-content2-inner1 .number-text-main p,
  .frame4-about-content2 .about-content2-inner2 .number-text-main p,
  .frame4-about-content2 .about-content2-inner3 .number-text-main p {
    font-family: "NimbusBold";
    font-size: 18px;
    color: #000;
  }
}
@media screen and (max-width: 991px) {
  .main-header-abs4 img {
    width: 400px;
  }
  .main-header-abs4 span {
    padding: 8px 8px;
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .frame4-about-content1,
  .frame4-about-content2 {
    width: 100%;
  }
  .main-header-abs4 img {
    height: 60px;
  }
  .frame4-what-we-do .heading-main h3,
  .frame4-about .heading-main h3 {
    font-size: 26px;
  }
  .main-header-abs4 img {
    width: 250px;
  }
  .main-header-abs4 span {
    font-size: 16px;
  }
}

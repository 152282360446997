.frame1-about {
  margin: 50px 0;
}
.frame1-about-logo {
  margin: 30px 0;
}
.main-header-funds-logo {
  position: relative;
}
.main-header-abs {
  position: absolute;
  top: 0;
  left: 30px;
  bottom: 0;
  height: fit-content;
  width: fit-content;
  margin: auto;
}
.main-header-abs p {
  padding: 8px 15px;
  color: #000;
  font-size: 26px;
  font-family: "TWKbold";
  border-radius: 8px;
}
.main-header-abs img {
  width: 550px;
  /* height: 70px; */
}

.frame1-funds,
.frame1-launch {
  width: 80%;
  margin: 0 auto;
}
.frame1-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 70px 0 0 0;
}
.frame1-funds .heading-main h3 {
  font-size: 35px;
  font-family: "Bwbold";
}
.frame1-launch-data {
  margin: 100px 0 10px 0;
}
.frame1-fund-data,
.frame1-launch-data {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.frame1-fund-content1,
.frame1-fund-content2,
.frame1-fund-content3 {
  width: 27%;
}
.frame1-launch-content1,
.frame1-launch-content2,
.frame1-launch-content3 {
  width: 26%;
}
.frame-fund-coming {
  margin: 100px 0 50px;
}
.frame-fund-coming h4 {
  color: #28343c;
  font-size: 38px;
  font-family: "Bwbold";
}
.frame-fund-anchor {
  margin: 20px 0 0 0;
}
.frame-fund-coming span a {
  background-color: #5348ce;
  padding: 8px 15px;
  color: #ffffff;
  font-size: 20px;
  font-family: "TWKregular";
  border-radius: 8px;
}
@media screen and (max-width: 1166px) {
  .frame1-fund-content1,
  .frame1-fund-content2,
  .frame1-fund-content3 {
    width: 30.5%;
  }
  .frame1-launch-content1,
  .frame1-launch-content2,
  .frame1-launch-content3 {
    width: 31%;
  }
  .frame-fund-coming {
    margin: 70px 0 50px;
  }
}
@media screen and (max-width: 991px) {
  .frame1-fund-content1,
  .frame1-fund-content2,
  .frame1-fund-content3 {
    width: 48%;
  }
  .frame1-launch-content1,
  .frame1-launch-content2,
  .frame1-launch-content3 {
    width: 100%;
  }
  .frame-fund-coming {
    margin: 50px 0 50px;
  }
  .main-header-abs img {
    width: 400px;
  }
  .main-header-abs p {
    padding: 8px 8px;
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .frame1-funds,
  .frame1-launch {
    width: 90%;
  }
  .main-header-funds-logo img {
    height: 150px;
  }
  .main-header-abs img {
    height: 70px;
  }
  .frame1-funds .heading-main h3 {
    text-align: center;
  }
  .frame1-launch-data {
    margin: 50px 0 10px 0;
  }
  .frame1-fund-content1,
  .frame1-fund-content2,
  .frame1-fund-content3 {
    width: 64%;
    margin: 0 auto 25px;
  }
  .frame-fund-coming h4 {
    font-size: 26px;
  }
  .frame-fund-coming span a {
    font-size: 18px;
  }
  .main-header-abs img {
    width: 250px;
  }
  .main-header-abs p {
    font-size: 16px;
  }
}

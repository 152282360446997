.image-logo img{
    max-width: 255px;
}
.input-main input{
    border: none;
    outline: none;
    border-bottom: 1px solid #D0D5DD;
    margin: 0 0px 30px 0;
    padding: 0 0 10px 0; 
    width: 100%;
}
.input-main input::placeholder{
    color:#737C8A;
    font-size: 16px;
    font-family: "TWKregular";
 }
 .login-content h3{
    color: #5348CE;
    font-size: 36px;
    font-family: "Bwbold";
 }
 .login-content h6{
    color: #28343C;
    font-size: 20px;
    font-family: "TWKregular";
    padding: 10px 0 0px 0;
 }
 .login-content p{
    color: #28343C;
    font-size: 20px;
    font-family: "TWKregular";
    padding: 20px 0 20px 0;
 }
.login-foot{
    display: flex;
    width: 80%;
    margin: 10px auto;
}
.login-foot span{
    color: #000;
    font-size: 16px;
    margin-right: 20px;
}
.login-foot a{
    color:#5348CE;
    text-decoration: underline !important;
}
.login-foot a:hover{
    color:#5348CE;
}
@media screen and (max-width:1024px){
    .login-content h3 {
        font-size: 34px;
    }
    .login-content p{
        font-size: 18px;
     }
}
@media screen and (max-width:768px){
    .login-content h3 {
        font-size: 24px;
    }
    .login-content p{
        font-size: 16px;
     }
}
@media screen and (max-width:600px){
    .login-foot{
        width: 100%;
    }
    .login-content h3{
        font-size: 24px;
     }
     .login-content p{
        font-size: 16px;
     }
 }
.anoouncement-time p{
    color: #5348CE;
    font-size: 16px;
    font-family: "TWKregular";
    margin: 0 0 0 0 !important;
    padding: 0 0 0 10px;
}
@media screen and (max-width:991px) {
    .anoouncement-time p{
        font-size: 16px;
    } 
}
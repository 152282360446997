.WorkAbout-main{
    background: rgba(136, 125, 253, 0.5);
    padding: 150px 0;
}
.WorkAbout-main h4{
    color: #0F1B23;
    font-size: 90px;
    font-family: "Bwbold";
}
.WorkAbout-main span,.WorkAbout-main a{
    color: #5348CE;
    font-size: 16px;
    font-family: "TWKregular";
}
.WorkAbout-main a{
    color: #5348CE;
}
@media screen and (max-width:991px) {
    .WorkAbout-main h4{
        font-size: 70px;
    }
    .WorkAbout-main{
        padding: 80px 0;
    }
}
@media screen and (max-width:600px){
    .WorkAbout-main h4 {
        font-size: 50px;
    }
}
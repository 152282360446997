.login-page-main{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 15px;
}
.login-page-main .login-image-bg{
    background: url('../../assets/images/login_screen1.png');
    background-repeat: no-repeat;
    background-position: right;
    background-size: 100% 100%;
    text-align: right;
    padding: 10px 0 0 0;
}
.login-page-main .login-image a{
   color: #fff;
   background-color: #5348CE;
   border-radius: 5px;
   padding: 6px  16px;
   font-size: 14px;
   margin:40px 15px 0 0;
}
.login-page-main .signup-image-bg{
    background: url('../../assets/images/sign_up.png');
    background-repeat: no-repeat;
    background-position: right;
    background-size: 100% 100%;
    text-align: right;
    padding: 10px 0 0 0;
}
.login-page-main .login2-image-bg{
    background: url('../../assets/images/login_screen2.png');
    background-repeat: no-repeat;
    background-position: right;
    background-size: 100% 100%;
    text-align: right;
    padding: 10px 0 0 0;
}
.login-input-container, .login-image{
    width: 45%;
}
.login-page-content1{
    padding: 100px 0 100px 50px;
    width: 75%;
}
.input-check{
    display: flex;
}
.input-check input{
    width: unset;
    margin-right:10px;
}
.account-detail{
    margin: 15px 0 0 0;
}
.anchor-style a{
    color: #5348CE !important;
    text-decoration: underline !important;
}
@media screen and (max-width:1024px){
    .login-page-content1{
        padding: 70px 0 70px 50px;
        width: 100%;
    }
}
@media screen and (max-width:768px){
    .login-page-content1{
        padding: 30px 0 0px 20px;
    }
    .login-input-container{
        width: 50%;
    }
}
@media screen and (max-width:600px){
    .login-page-main{
        flex-direction: column;
    }
    .login-page-content1{
        padding: 50px 0 50px 10px;
    }
    .login-page-main .login-image-bg, .login-page-main .signup-image-bg, .login-page-main .login2-image-bg{
        background:unset;
        padding: unset;
    }
    .login-input-container{
        width: 100%;
    }
 }
 @media screen and (max-width:320px){
    .input-check{
        flex-direction: column;
    }
 }
 
.about-team-main {
  /* width: 78%; */
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.about-team-contain {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 10px;
}
.about-team-bg {
  background-color: rgba(76, 66, 183, 0.8);
  padding: 15px 0;
}
.about-ceo-section {
  margin: 50px 0;
  text-align: center;
}
.about-ceo-content {
  margin: 0 auto;
  background-color: #fff;
}
.about-ceo-content p {
  color: #000;
  font-size: 36px;
  font-family: "BwBold";
}
.about-ceo-content span {
  color: #000;
  font-size: 18px;
  font-family: "BwBold";
}
.about-team-main-content p {
  color: #fff;
  font-size: 20px;
  font-family: "BwBold";
  text-align: center;
}
.mobile-board .mobile-board-title {
  margin: 0 0 75px 0;
}
.mobile-about-team-main {
  display: none;
}
.mobile-board-main,
.mobile-marketing-main {
  position: relative;
  min-height: 350px;
  width: 60%;
  margin: 0 auto;
}
.mobile-board-main img,
.mobile-marketing-main img {
  width: 150px;
}
.mobile-board-main .mobile-board-sec1 {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: fit-content;
  width: fit-content;
  margin: auto;
}
.mobile-marketing-sec1 {
  position: absolute;
  top: 0;
  right: 0;
}
.mobile-board-main .mobile-board-sec2,
.mobile-marketing-sec2 {
  position: absolute;
  left: 0;
  bottom: 0;
}
.mobile-marketing-sec4 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  /* bottom: 0; */
  margin: auto;
  height: fit-content;
  width: fit-content;
}
.mobile-board-main .mobile-board-sec3 {
  position: absolute;
  left: 0;
  bottom: 0;
}
.mobile-board-main .mobile-board-sec4,
.mobile-marketing-sec3 {
  position: absolute;
  right: 0;
  bottom: 0;
}
.mobile-board-main .mobile-board-sec5 {
  position: absolute;
  top: -65px;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: fit-content;
  width: fit-content;
}
.mobile-tech-team .about-logo-sec img,
.mobile-estate-team .about-logo-sec img,
.mobile-crypto-manager .about-logo-sec img {
  width: 200px;
}
.mobile-other-members img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.mobile-tech-team .about-logo-main h4,
.mobile-estate-team .about-logo-main h4,
.mobile-crypto-manager .about-logo-main h4 {
  padding: 0 0 0 0 !important;
}
.mobile-other-members {
  display: flex;
  justify-content: space-between;
}
.mobile-crypto-manager,
.mobile-estate-members,
.mobile-tech-members {
  display: flex;
  justify-content: space-around;
}
.aboutTeam-other:hover .image1 {
  display: none;
}
.aboutTeam-other:hover .image2 {
  display: unset;
}
@media screen and (max-width: 1440px) {
  .about-team-main {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1166px) {
  .about-team-main {
    width: unset;
  }
  .about-ceo-content p {
    font-size: 32px;
  }
}
@media screen and (max-width: 991px) {
  .about-team-main {
    display: none;
  }
  .about-ceo-content {
    width: 100%;
  }
  .about-ceo-content p {
    margin: 0 auto;
  }
  .about-ceo-content {
    text-align: center;
  }
  .mobile-about-team-main {
    display: unset;
  }
}
@media screen and (max-width: 767px) {
  .about-ceo-content p {
    font-size: 28px;
  }
  .mobile-board-main,
  .mobile-marketing-main {
    width: 100%;
  }
  .mobile-board-main img,
  .mobile-marketing-main img {
    width: 120px;
  }
  .mobile-other-members img {
    width: 120px;
    height: 120px;
  }
  .mobile-other-members,
  .mobile-crypto-manager,
  .mobile-estate-members,
  .mobile-tech-members {
    display: unset;
    text-align: center;
  }
  .mobile-tech-team .about-logo-sec img,
  .mobile-estate-team .about-logo-sec img,
  .mobile-crypto-manager .about-logo-sec img {
    width: 120px;
  }
}

.benefit-content {
  padding: 25px 0;
}
.benefit-content-head h3 {
  font-size: 48px;
  font-family: "BwBold";
}
.benefit-investing-head {
  width: 40%;
}
.benefit-investing-head h3,
.choosing-content h3 {
  font-size: 48px;
  font-family: "BwBold";
  max-width: 400px;
}
.benefit-investing-para {
  width: 40%;
}
.benefit-investing {
  padding: 50px 0;
  border-top: 1px solid #000;
  margin: 70px 0 0 0;
}
.benefit-investing-para .para-content p {
  padding: 0 0 0 0 !important;
}
.benefit-investing-arrow,
.choosing-content-arrow {
  padding: 30px 0 0 0;
}
.choosing-content {
  width: 45%;
  margin: auto 0;
}
.choosing-content2 {
  width: 35%;
}
.choosing-main {
  margin: 100px 0 200px 0;
}
.benefit-slider {
  margin-bottom: 100px;
}
@media screen and (max-width: 1166px) {
  .benefit-investing-head h3,
  .choosing-content h3,
  .benefit-content-head h3 {
    font-size: 40px;
  }
  .choosing-content {
    width: 50%;
  }
  .choosing-content2 {
    width: 40%;
  }
}
@media screen and (max-width: 768px) {
  .benefit-investing-head h3,
  .choosing-content h3 {
    max-width: unset;
  }
  .choosing-content,
  .benefit-content-head,
  .benefit-content-para,
  .benefit-investing-head,
  .benefit-investing-para {
    width: 100%;
  }
  .choosing-content2 {
    width: 50%;
    margin: 0 auto;
  }
  .choosing-main {
    margin: 70px 0 150px 0;
  }
}
@media screen and (max-width: 767px) {
  .benefit-investing-head h3,
  .choosing-content h3,
  .benefit-content-head h3 {
    font-size: 28px;
  }
  .choosing-content2 {
    width: 95%;
    margin: 25px auto 0;
  }
  .benefit-investing {
    margin: 30px 0 0 0;
  }
}

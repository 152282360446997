.header-sec {
  width: 90%;
  margin: 0 auto;
}
.header-main {
  background-image: url("../../../assets/images/Crypto-poster\ \(1\).png");
  background-repeat: no-repeat;
  background-size: 110% 100%;
  height: 80vh;
}
.headInnovation {
  padding: 80px 0 0 0;
}
.headInnovation span {
  background-color: #5348ce;
  color: #fff;
  padding: 1px 2px;
  border-radius: 5px;
}
.headInnovation h1 {
  color: #28343c;
  font-family: "glancyrRegular";
  font-weight: 400;
  font-size: 65px;
  max-width: 950px;
  margin: 0 !important;
  padding: 0 !important;
  letter-spacing: -4px;
  line-height: 80px;
}
.headInnovation h5 {
  color: #28343c;
  font-family: "TWKregular";
  font-size: 22px;
  max-width: 700px;
  padding: 0 !important;
  line-height: 30px;
  margin: 0 0 25px 0 !important;
}
@media screen and (max-width: 1440px) {
  .header-main {
    height: 75vh;
  }
}
@media screen and (max-width: 1166px) {
  .header-main {
    background-size: 110% 100%;
  }
  .headInnovation h1 {
    font-size: 48px;
    max-width: 746px;
    letter-spacing: -3px;
    line-height: 65px;
  }
}
@media screen and (max-width: 991px) {
  .headInnovation h1 {
    font-size: 40px;
    letter-spacing: -2px;
  }
  .headInnovation h5 {
    font-size: 20px;
    margin: 0 0 15px 0 !important;
  }
  .headInnovation a {
    font-size: 16px;
  }
  .header-main .header-anc {
    margin: 20px 0 0 15px;
  }
}
@media screen and (max-width: 767px) {
  .headInnovation h1,
  .headInnovation h5 {
    color: #fff;
  }
  .header-main {
    /* background-image: url("../../../assets/images/Crypto-poster\ sequie.png"); */
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)),
      url("../../../assets/images/Crypto-poster\ sequie.png");
    /* background-size: 180% 100%; */
    height: 60vh;
  }
  .headInnovation h1 {
    font-size: 24px;
    line-height: unset;
  }
  .headInnovation {
    padding: 50px 0 0 0;
  }
  .headInnovation p,
  .headInnovation h5 {
    font-size: 18px;
  }
}

@media screen and (max-width: 575px) {
  .header-main {
    height: 65vh;
  }
}

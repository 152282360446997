.counter-main h4{
    font-family: "Bwbold";
    font-size: 80px;
    color: #000;
    text-align: center;
}
.counter-image{
    text-align: center;
    margin: 0px 0 20px 0;
}
.counter-image img{
    width: 90px;
    height: 90px;
}
.counter-main h6{
   font-family: "Inter", sans-serif;
    font-size: 23px;
    color: #000;
    text-align: center;
    font-weight: 500;
}
@media screen and (max-width:1166px){
    .counter-main h6{
        font-size: 16px;
    }
}
@media screen and (max-width:768px){
    .counter-main h4{
        font-size: 70px;
    }
    .counter-main h6{
        font-size: 16px;
    }
}

.contact-sec{
    margin: 40px 0 0 0;
}
.contact-sec .contact-header{
    padding: 15px 0 15px 30px;
}
.contact-sec .contact-header h3{
    font-size: 36px;
    color:#5348CE;
    line-height: 44px;
    font-family: "Bwbold";
    margin: 0 !important;
    padding: 10px 0;
}
.contact-sec .contact-header p{
    font-size: 20px;
    color:#667085;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    margin: 0 !important;
    padding: 0 !important;
}
.contact-content-main{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 50px 0 0 0;
}
.contact-content-navs{
    width: 50%;
}
.contact-content-form{
    width: 45%;
    background-color: #F9FAFB;
    border-radius: 16px;
    padding: 25px 25px;
}
@media screen and (max-width:768px) {
    .contact-content-navs{
        width: 98%;
        margin: 0;
    }
    .contact-content-form{
        width: 98%;
    }
}
@media screen and (max-width:767px){
    .contact-sec .contact-header h3{
        font-size: 28px;
    }
    .contact-sec .contact-header{
        padding: 15px 0 15px 10px;
    }
}
.qImage img{
    width: 12px;
    height: 12px;
    margin-top: -4px;
}
.qImage{
    background-color: #fff;
    padding:2px 5px;
    border-radius: 50%;
    margin-right: 12px;
}
.question-direction{
    display: flex;
}
.question-content h2{
    color: #fff;
    font-size: 45px;
    font-family: "TWKbold";
}
.question-content h3{
    color: #fff;
    font-size: 30px;
    font-family: "TWKregular";
    max-width: 450px;
}
.question-content p{
    color: rgba(255, 255, 255, 0.6);
    font-size: 20px;
    font-family: "TWKregular";
    padding: 20px 0;
}
.question-content{
    border-bottom: 1px solid #fff;
    margin: 20px 0 40px 0;
}
.question-direction .arrow-img{
    background-color: #fff !important;
}
.question-direction .arrow-img span{
    font-weight: bolder;
    color: #000 !important;
}
.question-direction a,.question-direction span{
    color: #fff ;
    font-family: "TWKregular";
}
.question-direction a:hover{
    color: #fff;
}
@media screen and (max-width:1166px){
    .question-content h2{
        color: #fff;
        font-size: 36px;
        font-family: "TWKbold";
    }
    .question-content h3{
        color: #fff;
        font-size: 24px;
        font-family: "TWKbold";
    }
    .question-direction a,.question-direction .question-mar{
        font-size: 15px;
        margin-right: 5px;
    }
}
@media screen and (max-width:991px){
    /* .question-content h2{
        font-size: 36px;
    } */
    .question-content h3{
         max-width: unset;
    }
    /* .question-content p{
        font-size: 18px;
    } */
}
@media screen and (max-width:600px){
    .question-direction .arrow-img{
        padding: 0 4px;
    }
    .question-content h2{
        font-size: 30px;
    }
    .question-content h3{
        font-size: 20px;
    }
    .question-content p{
        font-size: 16px;
    }
    .question-direction{
        flex-direction: column;
    }
    .question-direction .arrow-anchor{
        padding-left: 8px;
    }
    .qustion-mar{
        margin: 8px 0;
    }
}
@media screen and (max-width:375px){
    .question-direction{
        display: flex;
        flex-direction: column;
    }
    
}
.work-content2{
    width: 50%;
    margin-left: auto;
    padding: 100px 70px 0 0;
}
/* .work-content-sec{
    background: rgba(136, 125, 253, 0.5);
    padding: 150px 0;
}
.work-content-sec h4{
    color: #0F1B23;
    font-size: 90px;
    font-family: "Bwbold";
}
.work-content-sec span,.work-content-sec a{
    color: #5348CE;
    font-size: 16px;
    font-family: "TWKregular";
}
.work-content-sec a{
    color: #5348CE;
} */
@media screen and (max-width:991px) {
    .work-content2{
        width: 95%;
        margin:0  auto;
        padding: 50px 30px 0 0;
    } 
    /* .work-content-sec h4{
        font-size: 70px;
    }
    .work-content-sec{
        padding: 80px 0;
    } */
}
/* @media screen and (max-width:600px){
    .work-content-sec h4 {
        font-size: 50px;
    }
} */
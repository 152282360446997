.or-main{
   color: #28343C;
   font-family: "TWKregular";
   margin: 20px 0 40px 0;
}
.or-main span{
    position: relative;
 }
.or-main span::after{
    content: "";
    position: absolute;
    border: 1px solid #D0D5DD;
    width: 150px;
    bottom: 2px;
    left: 25px;    
 }
 .or-main span::before{
    content: "";
    position: absolute;
    border: 1px solid #D0D5DD;
    width: 150px;
    bottom: 2px;
    right: 25px;    
 }
 @media screen and (max-width:768px){
   .or-main{
      margin: 20px 0 20px 0;
   }
}
@media screen and (max-width:600px){
   .or-main span::after{
      width: 120px;   
   }
   .or-main span::before{
      width: 120px;   
   }
}
.latest-news-card-main {
  /* box-shadow: 0 0 12px gray; */
  border-radius: 12px;
  padding: 10px;
  /* min-height: 250px; */
  flex-wrap: wrap;
}
.latest-news-card-main h4 {
  margin: 15px 0;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  color: #000;
}
.latest-news-card-main p {
  color: #5348ce;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  margin: 8px 0 0 0;
}

.popover-pad {
  position: relative;
}
.popover-pad .btn-close {
  background-color: #fff;
  border: 3px solid #000;
  border-radius: 50px;
  position: absolute;
  right: -32px;
  top: 10px;
}
.popover-img img {
  max-width: 250px;
}
/* .getapp-modal .modal-content{
    width: 70%;
    margin: 0 auto;
} */
@media screen and (max-width: 600px) {
  .popover-pad .btn-close {
    position: unset;
  }
  .popover-img img {
    max-width: 150px;
  }
}

.featureCard-main {
  background-color: #fbfbfc;
  border: 5px solid #e5eef0;
  border-radius: 24px;
  padding: 20px 10px;
  min-height: 250px;
  position: relative;
}
.featureCard-main:hover {
  background-color: #5348ce;
  border: 5px solid #000;
}
.featureCard-main:hover .featureCard-content h3 {
  color: #fff;
}
.featureCard-main:hover .featureCard-content p {
  color: #fff;
}
.featureCard-content h3 {
  font-size: 32px;
  color: #0f1b23;
}
.featureCard-content p {
  font-size: 21px;
  color: #000c14;
  position: absolute;
  bottom: 0;
}
@media screen and (max-width: 1166px) {
  .featureCard-main {
    padding: 25px 15px;
  }
  .featureCard-content h3 {
    font-size: 24px;
  }
  .featureCard-content p {
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  .featureCard-content h3 {
    font-size: 26px;
  }
  .featureCard-main {
    min-height: 220px;
    padding: 10px 15px;
  }
}

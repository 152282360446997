.accordion-button::after {
    background: url("../../assets/images/plus.png") !important;
    background-repeat: no-repeat !important;
}
.accord-main h4{
    font-size: 22px;
    color: #fff;
    font-family: "TWKregular";
    margin: 0 !important;
    padding: 0 !important;
}
.accordion-item{
    border:none !important;
}
.accord-main p{
    padding: 0 0 10px 0 !important;
    margin: 0 !important;
    color: #28343C;
    font-size: 16px;
    font-family: "TWKregular";
}
.accordion-header button{
    background-color: #5348CE;
    border-radius: 35px !important;
    outline: none;
}
.accordion-header .accordion-button:not(.collapsed){
    background: rgba(83, 72, 206, 0.1) !important;
    background-color: transparent;
    box-shadow: none;
    outline: none;
    border-radius: 35px 35px 0px 0px !important;
}
.accordion-item .accordion-collapse{
    background: rgba(83, 72, 206, 0.1);
    border-radius: 0px 0px 35px 35px !important;
}
.accordion-header .accordion-button:not(.collapsed) h4{
    color: #5348CE !important;
} 
@media screen and (max-width:1166px) {
    .accord-main h4 {
        font-size: 18px;
    } 
}
@media screen and (max-width:600px) {
    .accord-main h4 {
        font-size: 16px;
    } 
}
.Drop-down {
  margin: 0 10px 0 10px;
  color: #28343c;
  font-size: 24px;
  font-family: "TWKregular";
}
.navbar-data {
  margin: 0 0 0 50px;
}
.headlogo-image img {
  max-width: 280px;
}
.Headdemo-button button {
  color: #5348ce;
  font-size: 24px;
  font-family: "TWKregular";
  font-weight: 500;
  padding: 8px 30px;
  background: transparent;
  outline: none;
  border: none;
  margin: 0 0 0 20px;
  border-radius: 3.26px;
}
.Headdemo-button button:hover {
  color: #fff;
  background: #2f2f44;
}
.drop-span span {
  display: none;
}
.Drop-down .drop-items {
  padding: 8px 10px;
}
.Drop-down .drop-items p {
  word-wrap: break-word;
  width: 250px;
  white-space: normal;
}
.Drop-down .drop-items .dropdown-item:focus,
.Drop-down .drop-items .dropdown-item:hover {
  background-color: rgb(236, 236, 236) !important;
}
.Drop-down .drop-items .dropdown-item:active {
  background-color: transparent !important;
}
.drop-shraiah .shraiaf-icon {
  color: #5348ce;
  font-size: 26px;
  margin-right: 15px;
}
.drop-shraiah .sharaih-content h4 {
  color: #101828;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  padding: 3px 0;
  font-weight: 600;
}
.drop-shraiah .sharaih-content p {
  color: #667085;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  margin: 0 !important;
}
.invest-bottom {
  margin-top: 100px;
}
.flag-main {
  cursor: pointer;
}
/* .invest-bottom a{
    color: #5348CE;
    font-family: "TWKregular";
    font-size: 19px;
}
.invest-bottom a:hover{
    color: #5348CE;
}
.invest-bottom .invest-bottom-img{
    background-color: #5348CE;
    border-radius: 50%;
    padding: 2px 6px;
    margin-left: 10px;
}
.invest-bottom .invest-bottom-img img{
    width: 12px;
    height: 12px;
} */
/* .Headdemo-button{
    display: none;
} */
.language-main {
  position: relative;
}
.language-globe-icon {
  position: absolute;
  left: -26px;
  top: -3px;
}
.language-globe-icon .lang-color {
  color: #5348ce;
}
.flag-image img {
  width: 22px;
  height: 16px;
  margin: -4px 10px 0 0;
}
.shariah-color-image svg {
  fill: #5348ce;
}
.shariah-color-image path {
  color: #5348ce;
}
.main-header-lang {
  /* : 0 15px 0 0; */
  display: flex;
}
.main-header-lang h4 {
  margin: 0 10px 0 0;
}
.main-header-lang .flag-image {
  margin: auto 0;
}
.main-header-lang .flag-border {
  border-left: 3px solid gray;
  padding-left: 10px;
}
.drop-relative {
  position: relative;
}

.drop-abs-logo {
  padding: 8px;
  margin: 8px 0;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgb(0, 0, 0, 0.3);
  text-align: center;
  opacity: 0;
}
.drop-relative:hover .drop-abs-logo {
  opacity: 1 !important;
}
.drop-abs-logo img {
  width: 30px;
  height: 30px;
}
@media screen and (max-width: 1024px) {
  .Drop-down {
    font-size: 20px;
  }
  .Headdemo-button button {
    font-size: 18px;
    padding: 8px 20px;
    margin: 0 0 0 8px;
  }
}
@media screen and (max-width: 991px) {
  .navbar-data {
    margin: 0 0 0 0px;
  }
  .main-header-lang .flag-image {
    margin: auto 0px auto 10px;
  }
  .language-main {
    padding-left: 30px;
  }
  .language-globe-icon {
    left: 0px;
    top: -3px;
  }
  .header-relative {
    position: relative;
  }
  .resource-drop-image img {
    max-height: 200px;
  }
  .collapse-abs {
    position: absolute;
    top: 62px;
    right: 0;
    left: 0;
    height: 83vh;
    width: 100%;
    background-color: #e6e6e6;
    transition-timing-function: ease-in-out;
  }
}
@media screen and (max-width: 600px) {
  .Drop-down .drop-items p {
    width: 220px !important;
  }
  .headlogo-image img {
    max-width: 220px;
  }
}

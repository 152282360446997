.workmain-sec{
    padding: 70px 0 0 0;
}
.workmain-content h4{
    font-size: 44px;
    color: #000;
    font-family: "Bwbold";
}
.workmain-content{
    max-width: 445px;
}
.workmain-content p{
    font-size: 16px;
    color: #28343C;
    font-family: "TWKregular";
}
.workmain-image a{
    color: #5348CE;
    font-family: "TWKregular";
    font-size: 19px;
}
.workmain-image a:hover{
    color: #5348CE;
}
.workmain-image .work-mainimg{
    background-color: #5348CE;
    border-radius: 50%;
    padding: 2px 6px;
    margin-left: 10px;
}
.workmain-image .work-mainimg img{
    width: 12px;
    height: 12px;
}
.workmain-container,.workmain-content-image{
    width: 50%;
}
@media screen and (max-width:991px) {
    .workmain-content h4{
        font-size: 30px;
    }
    .workmain-content p{
        font-size: 14px;
        color: #28343C;
        padding: 0 !important;
        font-family: "TWKregular";
    }
}
@media screen and (max-width:767px){
    .workmain-sec{
        padding: 30px 0 0 0;
    }
    .workmain-container,.workmain-content-image{
        width: 95%;
        margin: 10px auto;
    }
}
.fundCard-main{
    border-left: 3px solid #7B61FF;
}
.fundCard-main h4{
    color: #28343C;
    font-size: 16px;
    font-family: "Bwbold";
    margin: 0 0 0 8px !important;
    padding: 0 0 0 0 !important;
}
.fundCard-main p{
    color: #28343C;
    font-size: 16px;
    font-family: "Bwregular";
    margin: 0 0 0 8px !important;
    padding: 0 0 0 0 !important;
}
/* @media screen and (max-width:991px){
    .fundCard-main h4,.fundCard-main p{
        font-size: 15px;
    }
} */
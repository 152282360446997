.trading-registeration{
    padding: 25px 0 0 0;
}
.trading-code h5{
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.2;
    font-family: "Bwbold";
    color: #000;
}
.trading-code h6{
    font-size: 1.4rem !important;
    font-weight: 600;
    line-height: 1.2;
    font-family: "Bwbold";
    color: #5348CE;
}
.trading-contact a,.trading-contact a:hover{
    color: #5348CE;
    font-size: 1.4rem !important;
}
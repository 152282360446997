.partnership-image-item img {
  max-width: 180px;
  margin: 0 auto;
  height: 100px;
}
.partnership-section {
  /* background-color: #ddd; */
}
.partnership-main-title h3{
  text-align: center;
  font-family: "TWKBold";
  padding: 30px 0 0 0;
}
.partnership-sec {
  padding: 15px 0;
}
.partnership-main {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 65%;
  margin: 0 auto;
}
.partnership-logo1 img,
.partnership-logo2 img {
  max-width: 250px;
  margin: 0 auto;
  height: 150px;
}
.partnership-logo1 h4,
.partnership-logo2 h4 {
  text-align: center;
  max-width: 250px;
  font-family: "TWKregular";
  margin: 8px 0 0 0;
}
@media screen and (max-width: 767px) {
  .partnership-image-item img {
    max-width: 150px;
  }
  .partnership-main {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .partnership-main {
    width: 90%;
    margin: 0 auto;
  }
  .partnership-logo1 img {
    height: 70px;
  }
  .partnership-logo2 img {
    height: 100px;
  }
  .partnership-logo2 {
    margin: 25px 0 0 0;
  }
}

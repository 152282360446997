.news-content-width {
  width: 80%;
  margin: 50px auto;
}
.latest-news-btns {
  display: flex;
  margin: 0 0 25px 0;
}
.latestnew-btn1 {
  margin: 0 15px 0 0;
}
@media screen and (max-width: 1440px) {
  .news-content-width {
    width: 90%;
  }
}
@media screen and (max-width: 767px) {
  .latest-news-btns {
  display: unset;
  margin: 0 0 25px 0;
  text-align: center;
}
.latestnew-btn1,.latestnew-btn2 {
  margin: 0 auto 25px auto;
}
}

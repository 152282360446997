.shariah-main {
  padding: 50px 0;
}
.shariah-content-flex {
  /* display: flex;
  justify-content: space-between;
  flex-wrap: wrap; */
}
.shariah-slider-data,
.shariah-content-flex {
  width: 90%;
  margin: 0 auto;
  text-align: center;
}
.shariah-content-flex h3 {
  color: #000000;
  font-size: 40px;
  font-family: "Bwbold";
}
.shariah-content-flex .para-content {
  margin: 0 auto;
  text-align: center;
  max-width: 600px;
}
.shariah-content-flex .para-content p {
  font-size: 24px;
}
.shariah-content3 {
  width: 90%;
  margin: 0 auto;
}
.shariah-sec {
  padding: 50px 0;
  flex-direction: row;
}
.shariah-content3 .shariah-content3-data p {
  font-size: 18px;
  font-family: "BwRegular";
}
.shariah-content3 h3 {
  font-size: 2.5rem;
  font-size: "Bwbold";
}
.shariah-image {
  max-width: 435px;
}
.shariah-image img {
  max-width: 435px;
  margin: 0 auto;
}
.shariah-icon {
  width: 14%;
}
/* .shariah-certificate .arrow-anchor {
  max-width: 500px;
  margin: 0 auto;
} */
.shariah-certificate {
  display: flex;
  justify-content: space-between;
  text-align: center;
  text-align: justify;
  /* width: 60%; */
  /* margin: 0 auto; */
}
.shariah-certificate-content1{
  margin: auto 0;
  padding: 0 15px 0 0;
}
.shariah-certificate-content2 img{
  width: 80px;
  height: 80px;
}
.shariah-content3-image img {
  max-width: 400px;
  margin: 30px auto;
}
.principle-matter-data p {
  color: #000000;
  font-size: 16px;
  font-family: "BwRegular";
}
.principle-matter-data h5 {
  color: #000000;
  font-size: 16px;
  font-family: "Bwbold";
}
 .shariah-certificate a{
    color: #5348CE;
    font-size: 16px;
    font-family: "Bwbold";
    text-decoration: underline;
  }
@media screen and (max-width: 1024px) {
  .shariah-content1 h3 {
    font-size: 40px;
  }
}
@media screen and (max-width: 991px) {
  .shariah-content1,
  .shariah-content2,
  .shariah-content3,
  .shariah-content-flex, .shariah-certificate {
    width: 98%;
    margin: 0 auto;
  }
  .shariah-content1 h3 {
    font-size: 30px;
  }
  .shariah-content3 p {
    max-width: unset;

  }
  .shariah-sec {
    flex-direction: column-reverse;
  }
  .shariah-image {
    margin: 0 auto;
  }
  .shariah-certificate {
    margin-bottom: 30px;
  }
  .shariah-icon {
    width: 42%;
  }
  .shariah-content-flex h3 {
    font-size: 30px;
  }
  .shariah-content-flex .para-content p {
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  .shariah-main {
    padding: 30px 0 0 0;
  }
  .shariah-content1 h3 {
    font-size: 24px;
  }
  .shariah-icon {
    width: 90%;
    margin: 20px auto;
  }
  .shariah-content3 h3 {
    font-size: 2rem;
    margin: 0 0 20px 0 ;
  }
  .shariah-content3-image img {
    max-width: 250px;
  }
    .shariah-content-flex h3 {
    font-size: 26px;
  }
  .shariah-content-flex .para-content {
    max-width: unset;
  }
  .shariah-content-flex .para-content p, .shariah-content3 .shariah-content3-data p {
    font-size: 16px;
  }
}

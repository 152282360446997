.termcard-head h4,.termcardlist-head h4{
    color: #5348ce;
    font-family: "TWKbold";
    font-size: 26px;
}
.termusedata h3{
    text-align: center;
    color: #5348CE;
    font-size: 40px;
    font-family: "TWKbold";
}
.termcard-head p,.termusedata p,.termcardlist-list ol li{
    color: #28343C;
    font-size: 18px;
    font-weight: 700;
    font-family: "TWKregular";
}
.termuse-main{
    padding: 35px 0;
}
.termcardlist-list ol{
    list-style-type: lower-alpha;
    margin-left: 20px;
}
@media screen and (max-width:767px){
    .termusedata h3{
        font-size: 36px;
    }
    .termcard-head p,.termusedata p,.termcardlist-list ol li{
        font-size: 16px;
    }
    .termcard-head h4,.termcardlist-head h4{
        font-size: 24px;
    }
}
.contact-input input{
    padding: 12px 16px 12px 16px;
    color:#667085;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    width: 100%;
}
.contact-form{
    padding:15px 15px;
}
.contact-form .Contact-input-fields{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.contact-textarea textarea{
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    outline: none;
}
.contactform-textarea{
    width: 100%;
}
.contactform-textarea textarea{
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    outline: none;
}
.contact-input{
    width: 45%;
}
.contact-Check{
    margin: 15px 0;
    width:100%;
}
.contact-Check input{
    width: unset;
    margin: 5px 12px 0 0;
    z-index: 1250000000000;
}
.contact-Check input[type="checkbox"]{
    width: 18px;
    height: 18px;
    cursor: pointer;
}
.contact-Check input[type="checkbox"]:checked {
    background-color: #5348CE !important;
}
.contact-btn{
    width: 100%;
}
.contact-btn button{
    background-color: #5348CE;
    color: #fff;
    text-align: center;
    border: 1px solid #5348CE;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    width: 100%;
    padding: 16px 0;
}
@media screen and (max-width:767px){
    .contact-form .Contact-input-fields{
       flex-direction: column;
    }
    .contact-input{
        width: 100%;
    }
    .contact-btn button{
        padding: 10px 0;
    }
}

.footer-list h4{
    color: #5348CE;
    font-family: "TWKbold";
    font-size: 28px;
}
.footer-list li a{
    color: #28343C;;
    font-size: 20px;
    font-family: "TWKregular";
}
.footer-list li{
    padding: 5px 0;
}
.foot-bottom-icon li{
    margin-left: 15px;
}
.footer-bottom p{
    color: #98A2B3;
    font-size: 16px;
    font-family: "TWKregular";
}
@media screen and (max-width:1166px) {
    .footer-list li a{
        font-size: 16px;
    }
}
@media screen and (max-width:768px){
    .footer-list h4{
        font-size: 20px;
    }
    .footer-list li a{
        font-size: 18px;
    } 
}
.comingsoon h4 {
  text-align: center;
  color: #28343c;
  font-family: "TWKbold";
  font-size: 3rem;
  margin: 50px 0 0 0;
}
@media screen and (max-width: 767px) {
  .comingsoon h4 {
    font-size: 1.5rem;
  }
}

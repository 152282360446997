.google-main-sec button{
    background-color: white;
    color: #000 !important;
    width: 100%;
    justify-content: center;
    padding:5px 0 !important;
    margin: 15px 0 50px 0;
    font-family: 'Inter', sans-serif !important;
    font-weight: 600 !important;
    border:1px solid #D0D5DD !important;
    box-shadow: none !important;
    border-radius: 5px !important;
}
@media screen and (max-width:768px){
    .google-main-sec button{
        margin: 15px 0 20px 0;
    }
}
.HowWorkStarthead{
    width: 48%;
}
.HowWorkStarthead-content{
    width: 50%;
}
.Howitwork-image{
    margin: 0 0 70px 0;
}
.HowWorkStarted{
    border-top: 1px solid #0F1B23;
    padding: 50px 0 0 0;
}
.process-main-content,.process2-main-content{
    width: 50%;
    margin: auto 0;
}
.process-main-image img,.process2-main-image img{
    max-width: 356px;
}
.process-main-content p{
    max-width: 507px;
    padding:  0 0 5px 0 !important;
}
.HowWorkStarthead-content p a{
    color: #5348CE;
}
.HowWorkStarthead h3 {
    color: #000;
    font-size: 36px;
    font-family: "Bwbold";
    text-align: center;
}
.HowWorkStarthead-content p{
    max-width: 590px;
}
.process2-main-content p{
    max-width:600px;
    padding:  0 0 5px 0 !important;
}
.process-bottom{
    margin: 0 0 100px 0;
}
.control-main{
    padding: 30px 0; 
}
.control-main-head{
    width: 42%;
}
.control-main-content{
    width: 46%;
}
.control-main-head h3{
    color:#000000;
    font-size: 48px;
    font-family: "Bwbold";
}
@media screen and (max-width:1166px) {
    .control-main-head h3{
        font-size: 40px;
    }
    .control-main-content{
        width: 56%;
    }
    .process-main-content h3{
        font-size: 22px;
    }
}
@media screen and (max-width:991px) {
    .HowWorkStarthead{
        width: 100%;
    }
    .HowWorkStarthead-content{
        width: 100%;
    }
    .HowWorkStarthead-content p, .process-main-content p, .process2-main-content p{
        max-width: unset;
    }
}
@media screen and (max-width:768px) {
    .control-main-head,.control-main-content,.process-main-content,.process2-main-content{
        width: 100%;
    }
    .process-main-image ,.process2-main-image {
        margin: 15px auto;
    }
    .process-bottom{
        margin: 0 0 70px 0;
    }
    .control-main-head h3{
        font-size: 31px;
    }
    .HowWorkStarthead h3 {
        font-size: 24px;
    }
    .Howitwork-main .work-content-sec h4{
        font-size: 48px;
    }
}
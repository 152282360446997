.Funding-main {
  padding: 20px;
}
.broder-sec {
  border: 2px solid #5348ce;
  border-radius: 8px;
}
.Funding-text2 {
  width: 40%;
  padding: 10px 5px;
}
.Funding-text3 {
  width: 45%;
  padding: 10px 5px;
}
.Funding-text1 h3 {
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.2;
  font-family: "Bwbold";
}
.Funding-text2 h3,
.Funding-text3 h3 {
  text-align: center;
}
.Funding-text2 h3,
.Funding-text3 h3,
.funding-text4 h3,
.Funding-text5 h3 {
  padding: 5px 8px !important;
  font-size: 24px;
  font-family: "TWKbold";
  margin: 0 0 0 0 !important;
}
.Funding-text5 p {
  padding: 5px 8px !important;
  font-size: 18px;
  font-family: "TWKregular";
  margin: 0 0 0 0 !important;
}
.Funding-button1 a {
  color: #fff;
  padding: 10px 25px;
  background-color: #5348ce;
  border-radius: 10px;
  border: 1px solid #5348ce;
  font-family: "TWKbold";
  font-size: 18px;
}
.Funding-sec2 {
  margin: 25px 0;
}
.Funding-button1 a:hover {
  color: #5348ce;
  background-color: transparent;
  border-radius: 50px;
}
.Funding-button {
  margin: 20px 0;
}
.Funding-option-Coming p,
.Funding-option-live p {
  padding: 5px 0px !important;
  font-size: 18px;
  font-family: "TWKregular";
  margin: 0 0 0 0 !important;
}
.Funding-option-Coming label .anouncement-card,
.funding-round label .anouncement-card {
  background-color: #5348ce;
  margin: 5px 0px 5px auto !important;
  border-radius: 50px;
  color: #fff;
  padding: 0 8px !important;
  box-shadow: 0 0 2px #000;
  width: 40px;
  text-align: center;
}
.Funding-option-Coming span,
.Funding-option-live span {
  font-size: 12px;
  padding: 0 4px !important;
}
.Funding-option-live label img {
  max-width: 70px;
}
.Funding-option-live,
.Funding-option-Coming {
  display: flex;
  justify-content: space-between;
}
.Funding-button {
  display: flex;
  justify-content: space-around;
}
.funding-round {
  padding: 15px 0 30px 0;
}
.funding-round .funding-image img {
  max-width: 300px;
}
.funding-round p {
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.2;
  font-family: "Bwbold";
}
.funding-stages .invest-real-content p {
  font-weight: 400 !important;
  font-family: "Bwregular";
}
.funding-stages .funding-round h6 {
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.2;
  font-family: "Bwbold";
}
.paraStyle p {
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.2;
  font-family: "Bwbold";
}
.paraStyle p a {
  color: #000;
  text-decoration: underline;
}
.paraStyle p a:hover {
  color: #5348ce;
  text-decoration: underline;
}
.trading-registeration {
  padding: 25px 0 0 0;
}
.trading-code h5 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.2;
  font-family: "Bwbold";
  color: #000;
}
.trading-code h6 {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.2;
  font-family: "Bwbold";
  color: #5348ce;
}
.trading-contact a,
.trading-contact a:hover {
  color: #5348ce;
}
.funding-para-content p {
  font-family: "TWKregular";
  font-size: 1.125rem;
  color: #28343c;
  padding: 0px 0 10px 0 !important;
  margin: 0 !important;
}
.funding-para-content {
  width: 80%;
  margin: 0 auto;
}
@media screen and (max-width: 1166px) {
  .funding-round p {
    font-size: 2.7rem;
  }
  .funding-para-content p {
    font-size: 1.125rem;
  }
}
@media screen and (max-width: 991px) {
  .Funding-text2,
  .Funding-text3 {
    width: 100%;
    margin: 0 auto 25px auto;
  }
  .funding-para-content {
    width: 100%;
  }
  .funding-round p {
    font-size: 2rem;
  }
  .funding-para-content p {
    font-size: 1.125rem;
  }
}
@media screen and (max-width: 767px) {
  .Funding-option-live,
  .Funding-option-Coming,
  .Funding-button {
    display: unset;
  }
  .Funding-option-Coming p,
  .Funding-option-live p,
  .Funding-text5 p {
    font-size: 16px;
  }
  .Funding-option-Coming label .anouncement-card {
    margin: 5px 0px 5px 0 !important;
  }
  .Funding-button1 {
    margin: 25px 0 10px 0;
  }
  .Funding-main {
    padding: 20px 10px;
  }
  .funding-round p {
    font-size: 1.2rem;
  }
  .funding-round .funding-image img {
    max-width: 220px;
  }
  .funding-para-content p {
    font-size: 1rem;
  }
}

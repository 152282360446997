.footer-main {
  margin: 40px 0 0 0;
}
.footer-main .footer-logo img {
  max-width: 250px;
}
.footer-sec {
  padding: 50px 0 30px 0;
}
.foot-content-para {
  width: 36%;
}
.foot-content-para h4 {
  margin-top: 60px;
  color: #5348ce;
  font-size: 20px;
}
.foot-content-para h5 {
  color: #28343c;
  font-size: 20px;
}
.foot-content-para h6 {
  font-weight: bold;
}
.foot-content-para p,
.foot-content-para p a,
.foot-content-para h6 {
  color: #28343c;
  font-size: 18px;
}
.foot-content-para p span img,
.foot-content-para h4 span img,
.foot-content-para h6 span img {
  margin: 0 5px 0 0;
  width: 20px;
}
.footer-content {
  width: 25%;
}
.footer-link {
  margin: 80px 0 0 0;
}
.footer-link .footer-signin a {
  margin: 0 0px 0 0;
  color: #5348ce;
  font-size: 24px;
  font-family: "TWKbold";
  padding: 8px 30px;
  border-radius: 5px;
  border: 1px solid #fff;
}
.footer-link .footer-signin a:hover {
  color: #ffffff;
  background-color: #2f2f44;
  border: 1px solid #5348ce;
}
.switch-lang {
  text-align: right;
  border-bottom: 1px solid #eaecf0;
  padding: 8px 0;
}
.switch-lang span {
  color: #000000;
  font-size: 20px;
  font-family: "TWKregular";
  padding: 0px 8px;
}
.switch-lang img {
  width: 22px;
  height: 14px;
  margin-top: -5px;
}
.switch-right {
  border-right: 1px solid #98a2b3;
  margin-right: 3px;
}
.bottom-content a {
  color: #98a2b3;
}
.bottom-content a:hover {
  color: #5348ce;
}
.footinsta-logo a img {
  width: 35px;
  margin: -3px 0 0 0;
}
@media screen and (max-width: 768px) {
  .footer-link .footer-signin a {
    font-size: 18px;
    padding: 8px 20px;
  }
  .foot-content-para {
    width: 95%;
    margin-bottom: 25px;
  }
  .footer-content {
    width: 45%;
  }
  .footer-main {
    margin: 50px 0 0 0;
  }
}
@media screen and (max-width: 600px) {
  /* .foot-content-para{
        width: 95%;
        margin-bottom: 25px;
    } */
  .footer-sec {
    padding: 20px 0 30px 0;
  }
  .footer-main {
    margin: 30px 0 0 0;
  }
  .footer-content {
    width: 100%;
  }
  .bottom-content {
    width: 100%;
  }
  .foot-bottom-icon img {
    width: 30px;
  }
  .foot-bottom-icon ul {
    justify-content: center;
  }
}

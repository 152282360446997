.invest-head-image{
    margin: 0 0 50px 0;
}
.invest-real-image{
    width: 435px;
    margin: auto 0;

}.invest-real-content{
    width: 75%;
    margin: auto 0;
}
.invest-real-image img{
    max-width:435px;
    margin:0 auto;
}
.real-estate ,.invest-trading ,.residential{
    padding: 0px 0 180px 0;
}
.invest-realestate{
    padding: 50px 0 0 0;
    border-top: 1px solid #0F1B23;
}
.invest-trading{
    background-color: #F2F5FA;
}
.invest-trading .invest-realestate,.residential .invest-realestate{
    border: none;
}
.invest-realestate h3{
    max-width: 230px;
}
.invest-realestate p{
    padding: 0 0 3px 0;
}
.invest-fund{
    padding:50px 0;
}
.invest-fund .invest-fund-content1{
    width: 40%;
}
.invest-fund .invest-fund-content1 h3{
    font-size: 46px;
    color: #000;
    font-family: "Bwbold";
    max-width: 250px;  
}
.invest-fund .invest-fund-content2{
    width:37%;
}
.invest-fund .invest-fund-content2 p{
    padding: 0 0 3px 0 !important;
}
.investing-button{
    display: flex;
    justify-content: space-around;
}
.investing-button1 a{
    color: #fff;
    padding: 10px 25px;
    background-color: #5348CE;
    border-radius: 10px;
    border: 1px solid #5348CE;
    font-family: "TWKbold";
    font-size: 18px;
}
.investing-button1 a:hover{
    color: #5348CE;
    background-color: transparent;
    border-radius: 10px;
}
.investing-button{
    margin: 20px 0;
}
.invest-real-content h3{
    max-width: unset;
    font-size: 3rem;
}
.invest-real-content p{
    font-size: 18px;
}
@media screen and (max-width:768px) {
    .invest-real-image{
        width: 60%;
        margin: 0 auto 25px;
    
    }.invest-real-content{
        width: 100%;  
    } 
    .real-estate ,.invest-trading ,.residential{
        padding: 0px 0 80px 0;
    }
    .invest-fund .invest-fund-content2{
        width:50%;
    }
    .invest-fund .invest-fund-content1 h3{
        font-size: 40px;
    }
}
@media screen and (max-width:600px) {
    .invest-real-image{
        width: 80%;
    }
    .invest-fund .invest-fund-content1{
        width:100%;
    }
    .invest-fund .invest-fund-content2{
        width:100%;
    }
    .invest-fund{
        padding:30px 0;
    }
    .invest-fund .invest-fund-content1 h3{
        font-size: 29px;
        max-width: unset;
    }
    .investing-button{
        display: unset;
    }
    .investing-button1{
        margin-bottom: 25px;
    }
    .invest-real-content h3{
        font-size: 1.8rem;
    }
    .invest-real-content p{
        font-size: 16px;
    }
}
.bloom-main {
  display: flex;
  /* justify-content: space-evenly; */
  /* justify-content: space-between; */
  /* flex-wrap: wrap; */
}
.bloom-content {
  /* width: 53%; */
  /* width: 130px; */
  margin: auto 10px auto 15px;
}
.bloom-main .bloom-content h4 {
  color: #000;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  margin: 0 0 0 0 !important;
}
.bloom-main .bloom-content p {
  color: #000;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  padding: 0 0 0px 0;
  margin: 0 0px 0 0px !important ;
  max-width: 150px;
}

.bloom-main .bloom-content span {
  margin: 0 0 0 8px;
}
.bloomchart {
  /* width: 45%; */
}
.bloomchart p {
  margin: 0 0 0 0;
  text-align: center;
}
.bloomchart .css-i4bv87-MuiSvgIcon-root {
  font-size: 16px;
  margin: -6px 0 0 0;
}
.bloomchart{
  /* height: 130px !important; */
  width: 200px !important;
}
@media screen and (max-width: 1440px) {
  .bloom-main .bloom-content h4 {
    font-size: 16px;
  }
  .bloom-main .bloom-content p {
    font-size: 14px;
  }
}
@media screen and (max-width: 1024px) {
  .bloomchart canvas {
    width: 140px !important;
  }
}
@media screen and (max-width: 767px) {
  .bloomchart canvas {
    height: 50px !important;
    width: 160px !important;
  }
  .bloomchart {
    margin: 10px 0 0 0;
  }
}
/* @media screen and (max-width: 767px) {
    .bloomchart canvas {
        height: 50px !important;
        width: 140px !important;
    }
} */
@media screen and (max-width: 600px) {
  .bloom-main {
    /* justify-content: unset; */
  }
  .bloom-content {
    /* width: unset; */
    /* margin: auto 10px auto 0px; */
  }
}
